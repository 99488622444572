



















import { Component } from 'vue-property-decorator'
import { ApiError, Button, TextField } from '@focus/components'
import BaseComponent from '@/plugins/mixins'
import { RecruitLoginDetails } from '@/domains/auth'

@Component({
  components: { Button, TextField }
})
export default class RecruitLogin extends BaseComponent {
  details: RecruitLoginDetails = {}

  private show = false

  private get invalidDetails () {
    return !this.details.givenName || !this.details.surname || !this.details.email
  }

  public open () {
    this.show = true
  }

  private async login () {
    try {
      await this.$store.dispatch('auth/recruitLogin', this.details)
      this.show = false
      this.$emit('login')
    } catch (error) {
      if (error instanceof ApiError) {
        this.showError(error)
      } else {
        throw error
      }
    }
  }
}

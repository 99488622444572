













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Button, ScreenerTemplateRender } from '@focus/components'

@Component({
  components: { Button, ScreenerTemplateRender }
})
export default class ScreenerWelcome extends Vue {
  @Prop({ type: String, default: '' })
  private readonly template!: string
}









import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Checkbox } from '@focus/components'

@Component({
  components: { Checkbox }
})
export default class TermsAndConditionsCheckbox extends Vue {
  get termsAndConditionsAccepted () {
    return (val: boolean) => !!val || 'Please accept the terms and conditions to continue'
  }
}























import BaseComponent from '@/plugins/mixins'
import { ApiError, VuetifyForm } from '@focus/components'
import { Component } from 'vue-property-decorator'

import ContactDetailConfirmationField from './ContactDetailConfirmationField.vue'

@Component({
  components: { ContactDetailConfirmationField }
})
export default class ContactDetailConfirmation extends BaseComponent {
  private emailLoading = false

  private mobileLoading = false

  private email: string | null = null

  private mobile: string | null = null

  private acceptedThirdParty = false

  private get showMobile () {
    return this.$store.state.auth.user.role !== 'guest'
  }

  private get form () {
    return this.$refs.form as VuetifyForm
  }

  private get thirdPartyConfirmRules () {
    return [
      (value: boolean) => value === true || 'Please check the box to continue'
    ]
  }

  mounted () {
    this.fetchData()
  }

  async fetchData () {
    this.emailLoading = true
    this.mobileLoading = true
    try {
      const profile = await this.$store.dispatch('profile/my-profile')
      this.email = profile.email
      this.mobile = profile.mobile
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    } finally {
      this.emailLoading = false
      this.mobileLoading = false
    }
  }

  async saveEmail (value: string) {
    this.emailLoading = true
    this.email = value

    try {
      await this.$store.dispatch('profile/updateContact', { email: value })
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    } finally {
      this.emailLoading = false
    }
  }

  async saveMobile (value: string) {
    this.mobileLoading = true
    this.mobile = value

    try {
      await this.$store.dispatch('profile/updateContact', { mobile: value })
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    } finally {
      this.mobileLoading = false
    }
  }

  async confirm () {
    if (this.form.validate()) {
      this.$emit('confirm')
    }
  }
}















import BaseComponent from '@/plugins/mixins'
import { Prop, Component, Watch } from 'vue-property-decorator'

@Component
export default class ContactDetailConfirmationField extends BaseComponent {
  @Prop({ default: 'Label' })
  private readonly label!: string

  @Prop({ default: '' })
  private readonly value!: string

  @Prop({ type: Boolean, default: false })
  private readonly loading!: boolean

  private edit = false

  private internalValue = ''

  get icon () {
    return this.edit ? 'save' : 'edit'
  }

  @Watch('value', { immediate: true })
  private valueChanged (value: string) {
    this.internalValue = value
  }

  private toggleEdit () {
    if (this.edit) {
      this.$emit('save', this.internalValue)
    }

    this.edit = !this.edit
  }
}


















import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Modal, Form } from '@focus/components'
import TermsAndConditionsCheckbox from '@/domains/core/components/TermsAndConditionsCheckbox.vue'

@Component({
  components: { Modal, TermsAndConditionsCheckbox, Form }
})
export default class OpenTermsAndConditionsConfirm extends Vue {
  show = false

  storageKey = 'focusTermsAndConditionsAccepted'

  get form () {
    return this.$refs.form as Form
  }

  get isLoggedIn (): boolean {
    return this.$store.state.auth?.isLoggedIn
  }

  mounted () {
    if (!this.alreadyAccepted()) {
      this.show = true
    }
  }

  close () {
    this.show = false
  }

  alreadyAccepted () {
    return this.isLoggedIn || sessionStorage.getItem(this.storageKey) === 'true'
  }

  private confirmAndSave () {
    if (this.form.validate()) {
      sessionStorage.setItem(this.storageKey, 'true')
      this.$emit('accepted')
      this.close()
    }
  }
}














import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { Modal } from '@focus/components'

@Component({
  components: { Modal }
})
export default class TerminatedModal extends Vue {
  private message = ''

  private show = false

  open (message?: string) {
    this.message = message || 'Unfortunately you aren\'t what we\'re looking for.'
    this.show = true
  }

  close () {
    this.$emit('onConfirm')
    this.show = false
    this.message = ''
  }
}

































import { Component } from 'vue-property-decorator'

import {
  Screener,
  ScreenerComponent,
  ScreenerQuestion,
  SystemFile,
  NotFound,
  ScreenerResponseStatus,
  ScreenerQuestionAnswer,
  ErrorDetails,
  Banner,
  Button, ApiError, ScreenerVisibility
} from '@focus/components'
import { ErrorBanner } from '@/domains/core'
import { AnswerPayload } from '../screeners.store'

import ScreenerWelcome from '../components/ScreenerWelcome.vue'
import ScreenerComplete from '../components/ScreenerComplete.vue'
import TerminatedModal from '../components/TerminatedModal.vue'
import ContactDetailsConfirmation from '../components/ContactDetailsConfirmation.vue'
import OpenTermsAndConditionsConfirm from '../components/OpenTermsAndConditionsConfirm.vue'
import RecruitLogin from '../components/RecruitLogin.vue'
import BaseComponent from '@/plugins/mixins'

@Component({
  components: { Banner, Button, ErrorBanner, ScreenerComponent, ScreenerComplete, TerminatedModal, ScreenerWelcome, NotFound, OpenTermsAndConditionsConfirm, ContactDetailsConfirmation, RecruitLogin }
})
export default class ScreenerReadPage extends BaseComponent {
  get screenerId () {
    return +this.$route.params.id
  }

  get screener (): Screener | undefined {
    return this.$store.state.screeners.activeItem as Screener
  }

  get introductionTemplate () {
    return this.screener?.introductionTemplate
  }

  get completionTemplate () {
    return this.screener?.completionTemplate
  }

  get terminatedModal () {
    return this.$refs.terminatedModal as TerminatedModal
  }

  get termsAndConditionsModal () {
    return this.$refs.termsAndConditionsModal as OpenTermsAndConditionsConfirm
  }

  get recruitLogin () {
    return this.$refs.recruitLogin as RecruitLogin
  }

  private get canShowContactDetailsConfirmation () {
    return this.$store.state.auth.isLoggedIn && !this.isListRecruitment
  }

  showScreener = false

  showContactDetailConfirmation = false

  get error (): ErrorDetails<Screener> | null {
    const error = this.$store.state.screeners.error
    if (!error) {
      return null
    }

    return error
  }

  notFound: { title: string; code: string; text: string } | null = null

  get guestUsedError () {
    return this.error?.code === 'ALREADY_USED'
  }

  get genericError () {
    if (!['NOT_FOUND', 'ALREADY_USED'].includes(this.error?.code || '')) {
      return this.error
    } else {
      return null
    }
  }

  get isListRecruitment () {
    return this.screener?.visibility === ScreenerVisibility.LIST_RECRUITMENT
  }

  get screenerUntouched () {
    return this.screener?.responseStatus === ScreenerResponseStatus.PENDING
  }

  get screenerInProgress () {
    return this.screener?.responseStatus === ScreenerResponseStatus.IN_PROGRESS
  }

  get screenerComplete () {
    return this.screener?.responseStatus === ScreenerResponseStatus.COMPLETED || this.screener?.responseStatus === ScreenerResponseStatus.SOFT_TERMINATED
  }

  get screenerTerminated () {
    return this.screener?.responseStatus === ScreenerResponseStatus.HARD_TERMINATED
  }

  get questions () {
    return this.screener?.pages?.reduce<ScreenerQuestion[]>((questions, page) => {
      if (page.questions) {
        return questions.concat(...page.questions)
      } else {
        return questions
      }
    }, []) || []
  }

  get answers () {
    return this.questions.reduce<ScreenerQuestionAnswer[]>((answers, question) => {
      if (question.answers) {
        return answers.concat(...question.answers)
      } else {
        return answers
      }
    }, [])
  }

  get errorTitle () {
    return this.notFound?.title
  }

  get errorText () {
    return this.notFound?.text
  }

  get convertAccountRoute () {
    return { name: 'guest-conversion' }
  }

  get isLoggedIn (): boolean {
    return this.$store.state.auth?.isLoggedIn
  }

  async mounted () {
    await this.fetchData()
    if (this.termsAndConditionsModal?.alreadyAccepted()) {
      this.afterTermsAccepted()
    }
  }

  async beforeDestroy () {
    if (this.isLoggedIn && this.isListRecruitment) {
      await this.$store.dispatch('auth/logout')
    }
  }

  async fetchData () {
    try {
      await this.$store.dispatch('screeners/getSingle', { id: this.screenerId.toString() })
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.statusCode === 404) {
          this.notFound = {
            title: 'Screener Not Found',
            code: 'NOT_FOUND',
            text: 'We were not able to find the screener, either it has been removed or you do not have permission to view it'
          }
        }
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  async fetchLayout () {
    try {
      await this.$store.dispatch('screeners/getLayout', { id: this.screenerId.toString() })
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.statusCode === 404) {
          this.notFound = {
            title: 'Screener Not Found',
            code: 'NOT_FOUND',
            text: 'We were not able to find the screener, either it has been removed or you do not have permission to view it'
          }
        }
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  async begin () {
    if (!this.showScreener) {
      return
    }
    try {
      await this.$store.dispatch('screeners/start', this.screener)
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  async answer (data: { pageId: number; questions: ScreenerQuestion[] }) {
    if (!this.showScreener) {
      return
    }
    const payload: AnswerPayload = {
      screenerId: this.screenerId,
      pageId: data.pageId,
      questions: data.questions
    }
    try {
      const response: { terminated: boolean } = await this.$store.dispatch('screeners/answer', payload)
      if (response.terminated) {
        this.terminatedModal.open()
      }
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  acceptTermination () {
    this.$store.commit('screeners/markScreenerAsTerminated')
  }

  async finalAnswers (data: { pageId: number; questions: ScreenerQuestion[] }) {
    if (!this.showScreener) {
      return
    }
    const payload: AnswerPayload = {
      screenerId: this.screenerId,
      pageId: data.pageId,
      questions: data.questions
    }
    try {
      const response: { terminated: boolean } = await this.$store.dispatch('screeners/answer', payload)
      if (response.terminated) {
        this.terminatedModal.open()
      }

      if (this.canShowContactDetailsConfirmation) {
        this.showContactDetailConfirmation = true
      } else {
        await this.complete()
      }
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  async complete () {
    try {
      await this.$store.dispatch('screeners/complete', { id: this.screenerId })
      if (this.isListRecruitment) {
        await this.$store.dispatch('auth/logout')
      }
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  async uploadFiles (data: { question: ScreenerQuestion; files: File[] }) {
    try {
      await this.$store.dispatch('screeners/uploadFiles', data)
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  async removeFile (data: { question: ScreenerQuestion; file: SystemFile }) {
    try {
      await this.$store.dispatch('screeners/removeFile', data)
    } catch (error) {
      if (error instanceof ApiError) {
        await this.showError(error)
      } else {
        throw error
      }
    }
  }

  termsAndConditionsAccepted () {
    if (!this.termsAndConditionsModal?.alreadyAccepted()) {
      return
    }
    this.afterTermsAccepted()
  }

  private afterTermsAccepted () {
    if (this.isListRecruitment && !this.isLoggedIn) {
      this.recruitLogin.open()
    } else {
      this.showScreener = true
      this.fetchLayout()
    }
  }

  private async recruitLoggedIn () {
    await this.fetchLayout()
    this.showScreener = true
  }
}
